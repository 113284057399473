.view-container {
  // padding: 15px;
  background: white;
  .grid-search {
    padding-top: 15px;
    //padding: 15px 15px 0 15px;
  }
  .grid-container,
  .grid-body {
    padding: 0 15px;
  }
  .view-header {
    padding-left: 15px;
    padding-right: 15px;
  }
  .fs-line {
    height: 9px;
    background: #f1f1f1;
    margin-top: -10px;
    margin-bottom: 10px;
  }
}
.view-header {
  height: 45px;
  position: relative;
  padding-bottom: 11px;
  display: flex;
  .search-line {
    min-width: 150px;
  }
  .search-line > div {
    margin-left: 5px;
    margin-right: 10px;
  }
  .search-line > div > div {
    width: 200px;
    text-align: left;
  }
  .search-line > div:first-child {
    flex: 1;
  }
  .search-line > div .ivu-select-dropdown {
    max-height: 300px;
  }
  .btn-group {
    white-space: nowrap;
    button {
      margin-left: 10px;
      // padding: 5px 16px;
    }
  }
  .btn-group .ivu-dropdown-item {
    text-align: left !important;
  }
  .btn-group .ivu-dropdown-item:not(:last-child) {
    border-bottom: 1px dotted #eee;
  }
  .desc-text {
    margin-top: 5px;
    font-weight: bold;
    margin-bottom: 3px;
    font-size: 14px;
    color: #313131;
    white-space: nowrap;
    border-bottom: 2px solid #646565;
    i {
      font-size: 16px;
      position: relative;
      top: 1px;
      margin-right: 2px;
    }
  }
  .search-box {
    background: #fefefe;
    margin-top: 45px;
    border: 1px solid #ececec;
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    padding: 25px 40px;
    padding-bottom: 0;
    box-shadow: 0px 7px 18px -12px #bdc0bb;
  }
  .notice {
    font-size: 13px;
    color: #6b6b6b;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: 12px;
    flex: 1;
    left: 10px;
    margin-right: 20px;
  }
}

.table-info-cell-title {
  background-color: #f5f5f5 !important;
}
.box-com {
  > div.item {
    // margin-bottom: 10px;
    padding: 15px 17px 0 8px;
    margin-bottom: 12px;
    background: white;
  }
  > div.form-item {
    padding: 19px 16px 0px 5px;
    //box-shadow: 0 1px 7px rgb(199, 199, 199);
  }
  > div.table-item {
    padding: 0 10px;
    border-top: 1.5px solid #eaeaea;
  }
  .v-text {
    line-height: 27px;
  }
  .form-text {
    position: relative;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    margin-bottom: 14px;
  }
}

.form-closex {
  text-align: right;
  padding-bottom: 24px;
}
.form-closex button {
  margin-left: 10px;
  padding: 4px 13px;
}
.toolbar {
  padding: 3px 0px;
  width: 100%;
  display: flex;
  .title {
    line-height: 29px;
    border-bottom: none;
    font-size: 13px;
    font-weight: bolder;
    margin-bottom: 0;
    color: #5d5c5c;
    .icon {
      color: #009688;
      font-size: 18px;
    }

    i {
      line-height: 29px;
      border-bottom: none;
      font-weight: bolder;
      margin-bottom: 0;
      color: #5d5c5c;
      position: relative;
      margin-top: -4px;
      font-size: 14px;
    }
  }
  .btns {
    line-height: 28px;
    flex: 1;
    text-align: right;
    button {
      // border: none;
      // margin-left:15px;
      border: 0px;
      padding: 0px 9px;
      color: #292929;
    }
    button:hover{
      color: #0089f6;
    }
  }
}
